import { writable } from "svelte/store";

export function setScrollButtons() {
  let showLeftButton = writable(false);
  let showRightButton = writable(false);

  const handleScroll = (content) => {
    if (content) {
      console.log("handleScroll");
      const left = content.scrollLeft > 0;
      const right = content.scrollLeft + content.clientWidth < content.scrollWidth;
      showLeftButton.set(left);
      showRightButton.set(right);
    }
  };
  // Returning both the stores and functions allows each modal to have its own state and behavior
  return { showLeftButton, showRightButton, handleScroll, smoothScroll };
}

// Smooth scrolling
export const smoothScroll = (direction, container) => {
  const step = 200; // Adjust this value based on your desired scroll step
  // const container = document.getElementsByClassName("streamers-scrolldiv")[0];
  const currentScrollLeft = container.scrollLeft;
  const targetScrollLeft =
    direction === "left"
      ? Math.max(currentScrollLeft - step, 0)
      : Math.min(currentScrollLeft + step, container.scrollWidth - container.clientWidth);

  container.scrollTo({
    left: targetScrollLeft,
    behavior: "smooth"
  });
};

// setup eventlisteners
export const setupEventListeners = (content, handleScrollFunction) => {
  const onScroll = () => handleScrollFunction(content);
  content.addEventListener("scroll", onScroll);

  const onResize = () => handleScrollFunction(content);
  window.addEventListener("resize", onResize);

  // Return a cleanup function that removes the event listeners
  return () => {
    content.removeEventListener("scroll", onScroll);
    window.removeEventListener("resize", onResize);
  };
};
