<script>
  export let item;
  export let index;

  import { locale } from "svelte-i18n";
  import { keywords_store, startup_state, settings_store, recommended_titles_index } from "$lib/store.js";
  import { locale_options } from "$lib/LocaleConstants.js";
  import MovieDetail from "$lib/MovieDetail.svelte";
  import { PUBLIC_API_BASE_URL } from "$env/static/public";
  import { goto } from "$app/navigation";
  import { onDestroy } from "svelte";

  let movie = null;
  let isOpen = false;
  let backdropImageUrl = null;
  let movieTrailerUrl = null;
  let posterClicked = false;

  $: if (item) {
    movie = null;
    // if (movie) {
    //   defineMovie(item);
    // }
  }

  const unsubscribe = recommended_titles_index.subscribe(($recommended_titles_index) => {
    if (index == $recommended_titles_index && !isOpen) {
      openDetailModal(item);
    }
  });

  async function defineMovie(item) {
    const details = await getDetails(item.mowizz_id);
    if (details !== null) {
      movie = details;
      backdropImageUrl = getBackdropImageUrl(item);
      let trailerInfo = details.videos.find((video) => video.type === "TRAILER" || video.type === "CLIP");
      movieTrailerUrl = trailerInfo ? trailerInfo.url : "";
    }
  }

  async function openDetailModal(item) {
    isOpen = true;
    if (!movie) {
      await defineMovie(item);
    }
    if (movie) {
      document.body.style.overflow = "hidden";
      if (posterClicked) {
        trackClick();
        posterClicked = false;
      }
    }
  }

  function setTitlesIndex() {
    recommended_titles_index.set(index);
  }

  function closeDetailModal() {
    isOpen = false;
    document.body.style.overflow = "";
  }

  async function getDetails(movieId) {
    const endpoint = "/api/info/getMovieDetail";
    const encodedId = encodeURIComponent(movieId);
    const params = `/${encodedId}?region=${$settings_store.region}&locale=${locale_options[$locale]}`;
    const url = PUBLIC_API_BASE_URL + endpoint + params;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch details");
      }
      const response_json = await response.json();
      return response_json.data.title;
    } catch (err) {
      console.error(err.message);
      goto("/error");
    }
  }

  function addToKeywords(keyword) {
    const isElementPresent = $keywords_store.some((objItem) => objItem.id === keyword.id);
    if ($startup_state) {
      keywords_store.update(() => {
        return [];
      });
      startup_state.update(() => {
        return false;
      });
    }
    if (!isElementPresent) {
      keywords_store.update((existing_keywords) => {
        return [...existing_keywords, keyword];
      });
      trackKeyword(keyword);
    }
  }

  function getPosterImageUrl(item_json) {
    let poster = item_json.image_urls.find((i) => i.image_type === "POSTER");
    if (poster === null || poster === undefined || poster === "") {
      return "/no_poster.svg";
    } else {
      let url = poster.image_url.replace(/(\.jpg|\.png)/g, ".webp");
      return url;
    }
  }

  function getBackdropImageUrl(item_json) {
    let backdrop = item_json.image_urls.find((i) => i.image_type === "BACKDROP");
    if (backdrop === null || backdrop === undefined || backdrop === "") {
      return "/no_backdrop.svg";
    } else {
      return backdrop.image_url;
    }
  }
  async function trackKeyword(keyword) {
    await defineMovie(item);
    window._paq.push([
      "trackEvent",
      "Link",
      "click",
      `keyword added: ${keyword.displayText}; keyword-id: ${keyword.id}; of movie-name: ${movie.name}; movie-original-name: ${movie.original_name}; movie-id: ${movie.id}; locale: ${$locale}`
    ]);
  }
  function trackClick() {
    const KeywordTextValues = $keywords_store.map((item) => item.displayText);
    const joinedKeywordTextValues = KeywordTextValues.join(", ");
    const KeywordIDs = $keywords_store.map((item) => item.id);
    const joinedKeywordIDs = KeywordIDs.join(", ");
    window._paq.push([
      "trackEvent",
      "Link",
      "click",
      `viewed details of movie-name: ${movie.name}; movie-original-name: ${movie.original_name}; movie-id: ${movie.id}; locale: ${$locale}; search-keywords: ${joinedKeywordTextValues}; Keyword-ids: ${joinedKeywordIDs}}`
    ]);
  }

  onDestroy(() => {
    unsubscribe();
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="movie-item">
  <span role="button" tabindex="0" on:click={() => setTitlesIndex()} on:keypress={() => setTitlesIndex()}>
    <img src={getPosterImageUrl(item)} class="title-poster" alt={item.title} width="4" height="6" />
  </span>
  <div class="all-keywords">
    {#each item.suggested_tags as keyword, recommended_titles_index}
      <div
        role="button"
        tabindex="-1"
        class="keyword keyword{recommended_titles_index}"
        on:click={() => addToKeywords(keyword)}
        on:keypress={() => addToKeywords(keyword)}>
        {keyword.displayText}
      </div>
    {/each}
  </div>
</div>

<!-- FIXME: backdropImageUrl should be passed as response to getDetails() call> -->
<!-- see https://github.com/doklab/mowizz-search/issues/174 -->
<!-- {#if movie} -->
<MovieDetail {item} {movie} {backdropImageUrl} {movieTrailerUrl} {isOpen} on:close={closeDetailModal} />

<!-- {/if} -->

<!-- ---------------------------------------------------------------------- -->

<style>
  .movie-item {
    position: relative;
    text-align: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    z-index: 10;

    /* display: inline-block; */
  }

  .movie-item:hover {
    width: 110%;
    height: 110%;
    margin-top: -5%;
    margin-left: -5%;
    margin-bottom: -10%;
    z-index: 11;
  }

  .title-poster {
    width: 100%;
    height: auto;
    display: inline-block;
    color: var(--solid-red);
    font-size: 1.5rem;
    text-align: left;
    background-color: var(--dark-grey);
    border-radius: 0.5rem;
  }

  .movie-item:hover .title-poster {
    width: 100%;
  }

  .all-keywords {
    opacity: 0;
  }

  .movie-item:hover .all-keywords {
    opacity: 1;
  }

  .keyword {
    z-index: 2;
    display: block;
    cursor: pointer;
    position: absolute;
    background: var(--white-grey);
    color: var(--solid-grey);
    padding: 0.5rem 1rem;
    border-radius: 12px;
    font-size: 16px;
    margin: 5px;
    text-align: center;
    transform: translate(-50%, -50%);
    word-wrap: break-word;
    max-width: 9rem;
    inline-size: max-content;
    hyphens: auto;
    opacity: 0;
    border: 1px solid transparent;
  }

  .keyword:hover {
    cursor: pointer;
    background-color: var(--solid-blue);
    color: var(--white);
    border: 1px solid var(--white);
  }

  .movie-item:hover .keyword {
    opacity: 1;
    transition:
      opacity 0.3s,
      transform 0.3s,
      left 0.3s,
      top 0.3s;
  }

  .keyword0 {
    top: 24%;
    left: 20%;
  }

  .movie-item:hover .keyword0 {
    top: 20%;
    left: 12%;
  }

  .keyword1 {
    top: 50%;
    left: 8%;
  }

  .movie-item:hover .keyword1 {
    top: 50%;
    left: 0%;
  }

  .keyword2 {
    top: 76%;
    left: 20%;
  }

  .movie-item:hover .keyword2 {
    top: 80%;
    left: 12%;
  }

  .keyword3 {
    top: 24%;
    left: 80%;
  }

  .movie-item:hover .keyword3 {
    top: 20%;
    left: 88%;
  }

  .keyword4 {
    top: 50%;
    left: 92%;
  }

  .movie-item:hover .keyword4 {
    top: 50%;
    left: 100%;
  }

  .keyword5 {
    top: 76%;
    left: 80%;
  }

  .movie-item:hover .keyword5 {
    top: 80%;
    left: 88%;
  }

  @media (max-width: 480px) {
    .all-keywords {
      display: none;
    }
  }
</style>
