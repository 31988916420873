<script>
  import { startup_state, keywords_store, animation_finished, loading_keywords, is_tag_array } from "$lib/store.js";

  $: keywords = $keywords_store;

  function deleteKeyword(keyword) {
    keywords_store.update((keywords_old) => {
      return keywords_old.filter((t) => t.displayText !== keyword.displayText);
    });
    keywords = keywords_store;
  }

  function clearKeywords() {
    keywords_store.update(() => {
      return [];
    });
    keywords = keywords_store;
  }

  function flip(keyword) {
    if (!$startup_state) {
      keywords_store.update((existing_keywords) => {
        return existing_keywords.map((t) => {
          if (t.displayText === keyword.displayText) {
            t.incl_excl = t.incl_excl === "INCLUDE" ? "EXCLUDE" : "INCLUDE";
          }
          return t;
        });
      });
      keywords = keywords_store;
    }
  }

  function keywordColor(keyword) {
    let color = keyword.incl_excl === "INCLUDE" ? "keyword-blue" : "keyword-red";
    if ($startup_state) {
      color = `${color}-inactive`;
    }
    return color;
  }
</script>

<!-- ---------------------------------------------------------------------- -->

<div id="keywords" class="keywords">
  {#if $animation_finished}
    {#each keywords as keyword}
      <div class="keyword-unit {$startup_state && $is_tag_array}">
        <span
          class="keyword {keywordColor(keyword)}"
          class:fade-bg={$startup_state && $is_tag_array}
          role="button"
          tabindex="0"
          on:click={() => flip(keyword)}
          on:keypress={() => flip(keyword)}>
          {keyword.displayText}
        </span>
        {#if !$startup_state == true}
          <span
            class="delete-keyword"
            role="button"
            tabindex="0"
            on:click={() => deleteKeyword(keyword)}
            on:keypress={() => deleteKeyword(keyword)} />
        {/if}
      </div>
    {/each}
    {#if $loading_keywords}
      <div class="loader" />
    {/if}
    {#if keywords.length > 0 && !$startup_state == true}
      <div class="keyword-unit">
        <span
          class="clear-keywords"
          role="button"
          tabindex="0"
          on:click={() => clearKeywords()}
          on:keypress={() => clearKeywords()} />
      </div>
    {/if}
  {/if}
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .keywords {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin-bottom: 22px;
    margin-left: 8px;
    margin-right: 8px;
    width: 90%;
    transition: all 0.25s ease-in-out;
  }
  .keyword-unit {
    display: flex;
    gap: 3px;
    margin: 4px;
    -webkit-animation-fill-mode: both !important;
    animation-fill-mode: both !important;
  }
  .keyword {
    align-items: center;
    border-radius: 12px;
    color: var(--white);
    display: flex;
    padding: 10px 16px;
    align-self: center;
    white-space: nowrap;
  }
  .keyword-blue {
    background-color: var(--solid-blue);
    border: 1px solid var(--solid-blue);
    cursor: pointer;
  }
  .keyword-blue:hover {
    background-color: var(--bright-red);
    border: 1px solid var(--white);
    text-decoration: line-through;
    cursor: pointer;
  }
  .keyword-blue-inactive {
    background-color: var(--solid-blue);
    border: 1px solid var(--solid-blue);
  }
  .keyword-red {
    background-color: var(--bright-red);
    border: 1px solid var(--bright-red);
    text-decoration: line-through;
    cursor: pointer;
  }
  .keyword-red:hover {
    background-color: var(--solid-blue);
    border: 1px solid var(--white);
    text-decoration: none;
    cursor: pointer;
  }
  .keyword-red-inactive {
    text-decoration: line-through;
    background-color: var(--bright-red);
    border: 1px solid var(--bright-red);
  }
  .fade-bg {
    background-color: transparent;
    transition: all 2s;
  }
  .delete-keyword {
    background-image: url("/buttons/btn_delete_keyword.svg");
    background-position: center;
    background-repeat: no-repeat;
    color: var(--white);
    cursor: pointer;
    padding-right: 10px;
    width: 32px;
    height: 32px;
    margin-left: -12px;
    align-self: center;
  }
  .delete-keyword:hover {
    background-image: url("/buttons/btn_delete_keyword_hover.svg");
  }
  .clear-keywords {
    background-image: url("/buttons/btn_clear_keywords.svg");
    background-repeat: no-repeat;
    background-size: 60px;
    cursor: pointer;
    margin-top: -8px;
    margin-bottom: -8px;
    margin-left: 10px;
    width: 60px;
    height: 60px;
    border-radius: 60px;
  }
  .clear-keywords:hover {
    background-image: url("/buttons/btn_clear_keywords_hover.svg");
  }

  .loader {
    background-image: url("/icons/icon_bouncing-circles.svg");
    width: 50px;
    height: 52px;
    align-self: center;
    margin: 0 0 0 0;
  }

  @media (max-width: 480px) {
    .keywords {
      margin-bottom: 8px;
      margin-left: 0px;
      margin-right: 0px;
      width: 96%;
    }
  }
</style>
