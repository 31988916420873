<script>
  import { scale } from "svelte/transition";
  import { elasticInOut } from "svelte/easing";
  import { onMount } from "svelte";

  export let orientation;
  export let tickSize = 24;
  export let anchorSelector;

  let clientWidth, clientHeight;
  let style = "";

  $: if (anchorSelector) {
    updatePosition();
  }

  function updatePosition() {
    const anchorElement = document.querySelector(anchorSelector);
    if (anchorElement) {
      const rect = anchorElement.getBoundingClientRect();
      const parentRect = anchorElement.parentNode.getBoundingClientRect();
      switch (orientation) {
        case "top right":
          style = `margin-left: auto; margin-right: ${parentRect.right - rect.right}px;`;
          break;
        case "top left":
          style = `margin-left: ${parentRect.left - rect.left}px; margin-right: auto;`;
          break;
      }
    }
  }

  onMount(() => {
    updatePosition();
    window.addEventListener("resize", updatePosition);
    window.addEventListener("scroll", updatePosition);

    return {
      destroy() {
        window.removeEventListener("resize", updatePosition);
        window.removeEventListener("scroll", updatePosition);
      }
    };
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div
  class="bubble"
  transition:scale={{ easing: elasticInOut, duration: 1500 }}
  bind:clientWidth
  bind:clientHeight
  {style}>
  <svg width={clientWidth} height={clientHeight} viewBox="0 0 {clientWidth} {clientHeight}">
    <rect x="0" y="0" width={clientWidth} height={clientHeight} rx="4" />

    {#if orientation == "bottom left"}
      <path d="M{tickSize * 2},{clientHeight} h-{tickSize} v{tickSize} Z" />
    {:else if orientation == "bottom right"}
      <path d="M{clientWidth - 2 * tickSize},{clientHeight} h{tickSize} v{tickSize} Z" />
    {:else if orientation == "top left"}
      <path d="M{tickSize * 2},0 h-{tickSize} v-{tickSize} z" />
    {:else if orientation == "top right"}
      <path d="M{clientWidth - 2 * tickSize},0 h{tickSize} v-{tickSize} Z" />
    {:else if orientation == "right top"}
      <path d="M{clientWidth},{tickSize} h{tickSize} l-{tickSize},{tickSize} Z" />
    {:else if orientation == "right bottom"}
      <path d="M{clientWidth},{clientHeight - tickSize} h{tickSize} l-{tickSize},-{tickSize} Z" />
    {:else if orientation == "left top"}
      <path d="M0,{tickSize} h-{tickSize} l{tickSize},{tickSize} Z" />
    {:else if orientation == "left bottom"}
      <path d="M0,{clientHeight - tickSize} h-{tickSize} l{tickSize},-{tickSize} Z" />
    {/if}
  </svg>

  <slot />
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .bubble {
    /* place-self: var(--place-self); */
    font-size: 0.9rem;
    color: var(--solid-blue);
    position: relative;
    padding: 0px 24px;
    line-height: 1rem;
    margin-bottom: 12px;
    z-index: 201;
    max-width: 78%;
  }

  .bubble svg {
    overflow: visible;
    position: absolute;
    left: 0;
    z-index: -1;
  }

  rect,
  path {
    fill: var(--fill-color, rgba(255, 170, 170, 1));
    /* fill: rgba(255, 170, 170, 1); */
  }
</style>
