<script>
  import { onMount } from "svelte";
  import { PUBLIC_TEADS_ID } from "$env/static/public";

  // export let active = false;

  console.log("Teads updated");

  function initTeads(document) {
    // ----------------------------------------------------------------
    // ACTIVATE this code block to skip Teads AFTER the Teads functionality is fixed
    // otherwise it will only create more confusion.
    // ----------------------------------------------------------------
    // if (!$settings_store.consent_ad_cookies) {
    //   // console.log("Skipping ad cookies.");
    //   return;
    // }
    // ----------------------------------------------------------------

    let script = document.createElement("script");
    script.className = "teads";
    script.async = true;
    script.type = "text/javascript";
    script.src = "https://a.teads.tv/page/" + PUBLIC_TEADS_ID + "/tag";

    document.body.appendChild(script);
  }

  // function cleanupTeads() {
  //   const teads = document.querySelector(".teadsbanner");
  //   if (teads) {
  //     teads.remove();
  //   }
  // }

  onMount(() => {
    initTeads(window.document);
  });
  // onDestroy(() => {
  //   // if (active) {
  //   cleanupTeads();
  //   // }
  // });
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="ad banner1"><div class="teadsbanner" id="teadsbanner1"></div></div>
<div class="ad banner2"><div class="teadsbanner" id="teadsbanner2"></div></div>
<div class="ad banner3"><div class="teadsbanner" id="teadsbanner3"></div></div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .ad {
    grid-column: 1 / 3;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .teadsbanner {
    color: var(--mowizz-logo-blue);
    background-color: var(--dark-grey);
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0.5rem;
    overflow: hidden;
    aspect-ratio: 1/1;
    object-fit: scale-down;
  }
  .banner1 {
    grid-row: 3;
  }
  .banner2 {
    grid-column: -3 / -1;
    grid-row: 8;
  }
  .banner3 {
    grid-row: 13;
  }
  @media (max-width: 863px) {
    .ad {
      place-items: center;
    }
    .teadsbanner {
      width: 100%;
    }
    .banner2 {
      grid-row: 15;
    }
    .banner3 {
      grid-row: 27;
    }
  }
  @media (max-width: 611px) {
    .ad {
      grid-column: 1;
      place-items: center;
    }
    .teadsbanner {
      width: 100%;
    }
    .banner2 {
      grid-column: 1;
      grid-row: 20;
    }
    .banner3 {
      grid-row: 40;
    }
  }
</style>
