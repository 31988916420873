<script>
  import { _ } from "svelte-i18n";
  import { goto } from "$app/navigation";
  import { selected_streamers } from "$lib/store.js";
  import { onMount, afterUpdate } from "svelte";
  import { PUBLIC_API_BASE_URL } from "$env/static/public";
  import { getTitles } from "$lib/search.js";
  import streamerIcons from "$lib/SVGs.js";
  import { setScrollButtons, smoothScroll, setupEventListeners } from "$lib/scrollLine.js";

  const { handleScroll, showLeftButton, showRightButton } = setScrollButtons();
  let content;

  $: selected = $selected_streamers;
  let streamers = [];

  function toggleStreamer(streamer) {
    if (selected.length === streamers.length) {
      //deselect all others if all were selected
      selected_streamers.set([streamer]);
    } else if (selected.length === 1 && selected.find((s) => s.id === streamer.id)) {
      //select all if the last ist to be deselected
      selected_streamers.set(streamers);
    } else if (selected.find((s) => s.id === streamer.id)) {
      // toggle selection: deselect if selected
      selected_streamers.update((streamers_old) => {
        return streamers_old.filter((s) => s.id !== streamer.id);
      });
    } else {
      // toggle selection: select if deselected
      selected_streamers.update((streamers_old) => {
        return [...streamers_old, streamer];
      });
    }
    getTitles();
  }

  async function getStreamers() {
    const endpoint = "/api/info/streamerList"; //GET
    const params = "/CH"; //hardcoded for now
    // TODO: activate the following line when the streamers have the correct region
    // const params = `/${$settings_store.region}`;
    const url = PUBLIC_API_BASE_URL + endpoint + params;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch streamers");
      }
      const response_json = await response.json();
      return response_json.data.streamers;
    } catch (err) {
      console.error(err.message);
      goto("/error");
    }
  }

  onMount(() => {
    // Load streamers and set selected_streamers
    getStreamers().then((s) => {
      streamers = s;
      selected_streamers.set(s);
    });

    // streamer scroll listeners
    if (content) {
      // Call the external helper function and store the cleanup function
      const cleanup = setupEventListeners(content, handleScroll);
      // Return the cleanup function for Svelte to call when the component unmounts
      return cleanup;
    }
  });

  // check if a scroll-button is needed on a page load
  afterUpdate(() => {
    const images = document.querySelectorAll(".streamers-scrolldiv img");
    const totalImages = images.length;
    let loadedImages = 0;

    const handleImageLoad = () => {
      if (++loadedImages === totalImages) {
        handleScroll(content);
      }
    };

    images.forEach((img) => img.addEventListener("load", handleImageLoad));
  });
</script>

<!-- ---------------------------------------------------------------------- -->

<div class="streamers-component">
  <div class="streamers-heading">{$_("streamers_heading")}</div>
  <div class="streamers-scrolldiv" bind:this={content} role="presentation">
    <div class="streamers">
      {#each streamers as streamer}
        <span
          role="button"
          tabindex="0"
          on:click={() => toggleStreamer(streamer)}
          on:keypress={() => toggleStreamer(streamer)}>
          <img
            class={selected.some((s) => streamer.id === s.id) ? "streamer-icon-selected" : "streamer-icon"}
            alt={streamer.name}
            src={streamerIcons[streamer.name]}
            width="1"
            height="1" />
        </span>
      {/each}
    </div>
  </div>
  <div class="streamers-scroll-buttons">
    <div
      class="streamers-scroll-btn-left"
      on:mousedown={() => smoothScroll("left", content)}
      role="button"
      tabindex="0"
      aria-label="Scroll left"
      style={`visibility: ${$showLeftButton ? "visible" : "hidden"};`}>
    </div>
    <div
      class="streamers-scroll-btn-right"
      on:mousedown={() => smoothScroll("right", content)}
      role="button"
      tabindex="0"
      aria-label="Scroll right"
      style={`visibility: ${$showRightButton ? "visible" : "hidden"};`}>
    </div>
  </div>
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .streamers-component {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column;
    align-items: center; */
    overflow-x: hidden;
    white-space: nowrap;
    justify-content: center;
    text-align: center;
    position: relative;
    /* flex-direction: column;  */
    width: 100%;
  }

  .streamers-heading {
    flex-basis: 100%;
    flex-grow: 1;
    text-align: left;
    margin-left: 80px;
    margin-right: 80px;
    color: var(--mowizz-logo-blue);
    border-bottom: 1px solid var(--mowizz-logo-blue);
    margin-bottom: -1px;
  }

  .streamers-scrolldiv {
    position: relative;
    display: flex;
    scrollbar-width: none;
    overflow-x: scroll;
    white-space: nowrap;
    border-top: 1px solid var(--mowizz-logo-blue);
  }

  .streamers {
    display: flex;
    gap: 8px;
    border-radius: 8px;
    margin-top: 4px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 4px;
    padding-bottom: 16px;
    line-height: 0;
  }

  .streamers-scroll-buttons {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    float: left;
    pointer-events: none;
    overflow: hidden;
  }

  .streamers-scroll-btn-right,
  .streamers-scroll-btn-left {
    content: "";
    position: relative;
    height: 84px;
    width: 30px;
    top: 0;
    bottom: 0;
    flex-shrink: 0;
    cursor: pointer;
    pointer-events: auto;
    background-repeat: no-repeat;
    background-position: center;
  }

  .streamers-scroll-btn-left {
    background-image: url("/buttons/btn_streamer_scroll_left.svg");
    left: 0;
  }

  .streamers-scroll-btn-left:hover {
    background-image: url("/buttons/btn_streamer_scroll_left_hover.svg");
  }

  .streamers-scroll-btn-right {
    background-image: url("/buttons/btn_streamer_scroll_right.svg");
    right: 0;
  }

  .streamers-scroll-btn-right:hover {
    background-image: url("/buttons/btn_streamer_scroll_right_hover.svg");
  }

  .streamer-icon {
    width: 48px;
    height: auto;
    opacity: 0.2;
    border: 2px solid transparent;
    border-radius: 7px;
  }

  .streamer-icon-selected {
    width: 48px;
    height: auto;
    background-color: var(--mowizz-logo-blue);
    border: 2px solid var(--mowizz-logo-blue);
    border-radius: 7px;
  }

  .streamer-icon:hover,
  .streamer-icon-selected:hover {
    cursor: pointer;
    background-color: var(--mowizz-logo-blue);
    border: 1px solid var(--white);
    margin: 1px;
    border-radius: 7px;
    opacity: 1;
  }

  @media (max-width: 480px) {
    .streamers {
      margin-top: 0px;
    }
    .streamers-heading {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
</style>
